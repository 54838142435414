<template>
  <div class="personPage">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "personPage"
};
</script>